import { UserType } from '../models';

export const userCollectionMap: { [key: number]: string } = {
  [UserType.AGENCY]: 'agencies',
  [UserType.AGENT]: 'agents',
  [UserType.AUTHORITY]: 'authorities',
  [UserType.CARRIER]: 'carriers',
  [UserType.CONSUMER]: 'consumers',
  [UserType.EMPLOYEE]: 'employees',
  [UserType.LEADSELLER]: 'leadsellers',
};

export const getUserCollection = (type?: UserType) => {
  if (!type) return undefined;

  const userCollection = userCollectionMap[type];
  return userCollection;

  // switch (type) {
  //   case UserType.AGENT:
  //     return 'agents';
  //   case UserType.CONSUMER:
  //     return 'consumers';
  //   case UserType.EMPLOYEE:
  //     return 'employees';
  //   case UserType.LEADSELLER:
  //     return 'leadsellers';
  //   case UserType.CARRIER:
  //     return 'carriers';
  //   case UserType.AGENCY:
  //     return 'agencies';
  //   case UserType.AUTHORITY:
  //     return 'authorities';
  //   default:
  //     return undefined;
  // }
};

export const homePathMap: { [key: number]: string } = {
  [UserType.CONSUMER]: '/consumer',
  [UserType.EMPLOYEE]: '/employee',
  [UserType.AGENT]: '/agent',
  [UserType.CARRIER]: '/carrier',
  [UserType.LEADSELLER]: '/lead-seller',
  [UserType.AGENCY]: '/agency',
  [UserType.AUTHORITY]: '/authority',
};

export const getHomePath = (type?: UserType) => {
  if (!type) return '/';

  const homePath = homePathMap[type];
  return homePath || '/';
  // switch (type) {
  //   case UserType.CONSUMER:
  //     return '/consumer';
  //   case UserType.EMPLOYEE:
  //     return '/employee';
  //   case UserType.AGENT:
  //     return '/agent';
  //   case UserType.CARRIER:
  //     return '/carrier';
  //   case UserType.LEADSELLER:
  //     return '/lead-seller';
  //   case UserType.AGENCY:
  //     return '/agency';
  //   case UserType.AUTHORITY:
  //     return '/authority';
  //   default:
  //     return '/';
  // }
};
